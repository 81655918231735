import { AlertsService } from '@alerts/shared/alerts.service';
import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, NavigationStart, Router, RouterLink } from '@angular/router';
import { AppLoadService } from '@app/app-load.service';
import { ArchivesService } from '@app/core/shared/archives/archives.service';
import { IDB_STORES, IdbService } from '@app/core/shared/cache/idb.service';
import { ConfigurationsService } from '@app/core/shared/configurations/configurations.service';
import { Warehouse } from '@app/core/shared/warehouse';
import { SignalREvents } from '@app/notifications/shared/clients/signalr-status.client';
import { AysCommonsModule } from '@ays';
import { ModalCenteredComponent } from '@ays/commons/lib/components/modal-centered/modal-centered.component';
import { NavComponent } from '@ays/lib/components/nav/nav.component';
import { StateService } from '@core/shared/state/state.service';
import { environment } from '@environments/environment';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import * as _ from 'lodash-es';
import { interval, Subject, take, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../shared/authentication/authentication.service';
import { SignalRStatusService } from '@app/notifications/shared/handlers/signalr-status-service';
import { AlertsCount } from '@alerts/shared/alerts';
import { OpenLinkInNewTabDirective } from '@app/shared/directives/open-new-tab.directive';
import { WarehouseMapStatus } from '@app/notifications/shared/handlers/service-status-service';

@Component({
    selector: 'core-nav-custom',
    templateUrl: './nav-custom.component.html',
    styleUrls: ['./nav-custom.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        OpenLinkInNewTabDirective,
        RouterLink,
        NgFor,
        NgClass,
        AysCommonsModule,
    ],
})
export class NavCustomComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() parent: NavComponent;
    @ViewChild('refreshMapModal', { static: false }) refreshMapModal: ModalCenteredComponent;
    numOfAlerts: number;
    numOfArchives: number;
    numOfVariables: number;
    showNav: boolean;
    liteView: boolean;
    darkMode: boolean;
    isFullscreen: boolean;
    isHome: boolean;
    showFilters: boolean;
    isAuthorized: boolean;
    userName: string;
    userEmail: string;
    warehouses: Array<Warehouse> = [];
    warehouse: Warehouse;
    mapVersion: string;
    version = environment.version;
    configuration: any;
    viewExtraData = true;
    refreshMapSeconds = 30;
    fullscreenChangeHandler: () => void;
    reportStatusGroupName: string;
    destroyRef = inject(DestroyRef);
    private subscriptionDetails: Array<[groupName: string, supportedEvents: Array<SignalREvents>]>
    private ngUnsubscribeInterval: Subject<any> = new Subject();
    private readonly router = inject(Router);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly appLoadService = inject(AppLoadService);
    private readonly configurationsService = inject(ConfigurationsService);
    private readonly archivesService = inject(ArchivesService);
    private readonly stateService = inject(StateService);
    private readonly appInsights = inject(ApplicationInsights);
    private readonly idbService = inject(IdbService);
    private readonly alertsService = inject(AlertsService);
    private readonly signalRService = inject(SignalRStatusService);
    private _document: Document | null | any = inject(DOCUMENT, { optional: true });

    constructor() { }
    // constructor(
    //     private router: Router,
    //     private alertsCountStatusService: AlertsCountStatusService,
    //     private serviceStatusService: ServiceStatusService,
    //     private reportCountStatusService: ReportCountStatusService,
    //     private authenticationService: AuthenticationService,
    //     private appLoadService: AppLoadService,
    //     private configurationsService: ConfigurationsService,
    //     private archivesService: ArchivesService,
    //     private stateService: StateService,
    //     private appInsights: ApplicationInsights,
    //     private idbService: IdbService,
    //     private alertsService: AlertsService,
    //     @Inject(DOCUMENT) private _document: any,
    // ) {
    //     this.userName = '';
    //     const value =
    //         environment.mode === 'front'
    //             ? {
    //                   name: 'Oscar Lijo Busto',
    //                   userName: 'oscar.lijo@inditex.es',
    //               }
    //             : this.authenticationService.getUser();

    //     if (value) {
    //         this.warehouses = _.sortBy(this.appLoadService.warehouses, 'warehouse');
    //         appLoadService.getCurrentWarehouse.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.warehouse = res));
    //         appLoadService.getCurrentConfiguration.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
    //             this.configuration = res;
    //             this.viewExtraData = this.configuration.viewExtraData;
    //             this.liteView = this.configuration.liteMode;
    //             this.darkMode = this.configuration.darkMode;
    //         });
    //         this.userName = environment.mode === 'front' ? value.name : value.name ? value.name : '';
    //         this.userEmail = value.userName ? value.userName : '';
    //         this.stateService.getVersionMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => (this.mapVersion = res));
    //     }
    // }

    ngOnInit() {
        this.userName = '';
        const userDetails = this.authenticationService.getUser();
        this.warehouses = _.sortBy(this.appLoadService.warehouses, 'warehouse');
        this.appLoadService.getCurrentWarehouse.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => { this.warehouse = res });
        this.appLoadService.getCurrentConfiguration.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            this.configuration = res;
            this.viewExtraData = this.configuration.viewExtraData;
            this.liteView = this.configuration.liteMode;
            this.darkMode = this.configuration.darkMode;
        });
        this.userName = userDetails?.name || '';
        this.userEmail = userDetails?.userName || '';
        this.stateService.getVersionMap.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => (this.mapVersion = res));
        this.reportStatusGroupName = `${this.warehouse.warehouse}-${this.userEmail}`;


        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
            const warehouse = this.warehouse.warehouse;
            this.isHome =
                this.router.url.includes(`/warehouse/${warehouse}/zones`) ||
                this.router.url.includes(`/warehouse/${warehouse}/floor/`) ||
                this.router.url.includes(`/warehouse/${warehouse}/lines`);
            if (event instanceof NavigationStart || event instanceof NavigationEnd) {
                this.showNav = event.url === '/landing' ? false : true;
                $('.navbar-light .nav-item').removeClass('active');
                if (event.url.includes('/alerts') && !event.url.includes('/reports/')) {
                    $('.nav-item.item-2').addClass('active');
                } else if (event.url.includes('/warnings')) {
                    $('.nav-item.item-3').addClass('active');
                } else if (event.url.includes('/reports/')) {
                    $('.nav-item.item-6').addClass('active');
                } else if (event.url.includes('/variables')) {
                    $('.nav-item.item-4').addClass('active');
                } else if (event.url.includes('/scanners')) {
                    $('.nav-item.item-5').addClass('active');
                } else if (event.url.includes('/analytics/')) {
                    $('.nav-item.item-7').addClass('active');
                    $('div.full-screen-chart').removeClass('full-screen-chart');
                } else if (event.url.includes('/dashboard')) {
                    $('.nav-item.item-8').addClass('active');
                } else {
                    $('.nav-item.item-1').addClass('active');
                    $('.map-wrapper').removeClass('expand');
                }
            }
        });

        $(() => {
            $('.dropdown-menu a.dropdown-toggle').on('click', function (e) {
                const $el = $(this);
                $el.toggleClass('active-dropdown');
                if (!$(this).find('.dropdown-menu').hasClass('show')) {
                    $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
                }
                const $subMenu = $(this).find('.dropdown-menu');
                $subMenu.toggleClass('show');
                $subMenu.css({ top: -11, left: ($subMenu.width() + 3) * -1 });
                return false;
            });
        });
    }

    async ngAfterViewInit(): Promise<void> {
        this.subscriptionDetails = [
            [this.reportStatusGroupName, [SignalREvents.REPORT_COUNT_CHANGED]],
            [this.warehouse.warehouse, [SignalREvents.ACTIVE_ALERTS_COUNT_CHANGED]],
            [this.warehouse.warehouse, [SignalREvents.CANONICAL_MAP_CHANGED]],
        ];
        await this.subscribeToRealTimeEvents();

        this.fullscreenChangeHandler = () => {
            this.isFullscreen = !!document.fullscreenElement;
            this.stateService.setFullscreen(this.isFullscreen);
            if (this.isFullscreen) {
                this.stateService.setShowFilters(false);
            }
        };

        if (document.addEventListener) {
            document.addEventListener('fullscreenchange', this.fullscreenChangeHandler, false);
            document.addEventListener('mozfullscreenchange', this.fullscreenChangeHandler, false);
            document.addEventListener('MSFullscreenChange', this.fullscreenChangeHandler, false);
            document.addEventListener('webkitfullscreenchange', this.fullscreenChangeHandler, false);
        }
        // this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //     if (
        //         prevState !== VisibilityStates.active &&
        //         [VisibilityStates.active, VisibilityStates.passive].includes(state) &&
        //         this.alertsCountStatusService.isConnected()
        //     ) {
        //         this.subscribeToAlertCount();
        //         this.subscribeToReportCount();
        //         this.subscribeToWarehouseMapChanged();
        //     }
        // });
        const navBarElement = document.querySelector('nav > div');
        const logoElement = document.querySelector('div > a');
        const buttonElement = document.querySelector('nav > div > button');
        const equipmentIcon = document.createElement('ng-container');
        if (logoElement) logoElement.classList.add('flex-grow-1', 'text-start');
        equipmentIcon.innerHTML = `<div class="d-block d-lg-none me-2 me-sm-3 mt-2">
        <a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEquipments" aria-controls="offcanvasEquipments">
            <i class="icon-velocimeter" style="font-size: 28px; color: #dee2e;"></i>
        </a>
        </div>`;
        navBarElement.insertBefore(equipmentIcon, buttonElement);
        this.signalRService.getOnReconnectSubject().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async () => await this.subscribeToRealTimeEvents());
    }

    goToHome() {
        const restrictedUris = [
            `/warehouse/${this.warehouse.warehouse}/zones`,
            `/warehouse/${this.warehouse.warehouse}/lines`,
            `/warehouse/${this.warehouse.warehouse}/customs`,
        ];
        if (!restrictedUris.includes(this.router.url)) {
            this.router.navigate([`warehouse/${this.warehouse.warehouse}`]);
        }
    }

    async ngOnDestroy(): Promise<void> {
        await this.signalRService.endSubscription(this.subscriptionDetails);
        document?.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
        document?.removeEventListener('mozfullscreenchange', this.fullscreenChangeHandler);
        document?.removeEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
        document?.removeEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
    }

    async subscribeToRealTimeEvents() {
        try {
            await this.signalRService.endSubscription(this.subscriptionDetails);
            this.signalRService.startSubscription(this.subscriptionDetails)
                .then(subjects => {
                    subjects(this.warehouse.warehouse, SignalREvents.CANONICAL_MAP_CHANGED)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe(this.processWarehouseChangedNotification.bind(this));
                    subjects(this.warehouse.warehouse, SignalREvents.ACTIVE_ALERTS_COUNT_CHANGED)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe(this.processAlertsCountNotification.bind(this));
                    subjects(this.reportStatusGroupName, SignalREvents.REPORT_COUNT_CHANGED)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe(this.processReportsCountNotification.bind(this));
                });
            this.alertsService
                .getAlertsActiveCount()
                .pipe(take(1))
                .subscribe((response) => this.numOfAlerts = response.count);
            this.archivesService
                .getArchivesRemoteStorage()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((res) => this.numOfArchives = (res || []).length);
            document?.removeEventListener('fullscreenchange', this.fullscreenChangeHandler);
            document?.removeEventListener('mozfullscreenchange', this.fullscreenChangeHandler);
            document?.removeEventListener('MSFullscreenChange', this.fullscreenChangeHandler);
            document?.removeEventListener('webkitfullscreenchange', this.fullscreenChangeHandler);
        } catch (error) {
            console.error(error);
        }
    }

    processWarehouseChangedNotification(warehouseMap: WarehouseMapStatus) {
        this.appLoadService.getCurrentWarehouse.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(async (currentWarehouse) => {
            if (warehouseMap.WarehouseId.toUpperCase() === currentWarehouse.warehouse.toUpperCase()) {
                this.refreshMap(true);
            }
        });
    }

    processAlertsCountNotification(activeAlertsCount: AlertsCount) {
        this.numOfAlerts = activeAlertsCount.count;
    }

    processReportsCountNotification(reportsCount: number) {
        // if (reportsCount) {
        this.numOfArchives = reportsCount;
        // } else {
        // this.getReportsCount();
        // }
    }

    toggleFullscreen() {
        if (!this.isFullscreen) {
            const elem: any = this._document.body;
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                elem.msRequestFullscreen();
            }
        } else {
            if (this._document.exitFullscreen) {
                this._document.exitFullscreen();
            } else if (this._document.mozCancelFullScreen) {
                this._document.mozCancelFullScreen();
            } else if (this._document.webkitExitFullscreen) {
                this._document.webkitExitFullscreen();
            } else if (this._document.msExitFullscreen) {
                this._document.msExitFullscreen();
            }
        }
    }

    loginButtonClick(): void {
        this.authenticationService.getUser();
    }
    logoutButtonClick(): void {
        this.authenticationService.signOut();
        this.appInsights.clearAuthenticatedUserContext();
    }
    async changeWarehouse(warehouse) {
        let currentWarehouse = null;
        this.appLoadService.getCurrentWarehouse.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            currentWarehouse = res;
            if (currentWarehouse.hostName.toLowerCase() !== warehouse.hostName.toLowerCase()) {
                this.idbService.clear(IDB_STORES.HTTP);
            }
        });
        await this.appLoadService.setCurrentWarehouse({
            warehouse: String(warehouse.warehouse),
            displayName: String(warehouse.displayName),
            hostName: String(warehouse.hostName),
            warehouseMapCreationDate: warehouse.warehouseMapCreationDate,
        });

        this.configuration = this.appLoadService.cleanCurrentConfiguration(this.configuration);
        await this.configurationsService.saveConfigurationPromise(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);

        window.location.href = '/';
    }

    toggleViewMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.configuration.liteMode = !this.liteView;
            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
        }
    }

    toggleDarkMode(e) {
        if (!$(e.target).hasClass('active')) {
            this.configuration.darkMode = !this.darkMode;
            this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
        }
    }

    toggleExtraData() {
        this.configuration.viewExtraData = !this.viewExtraData;
        this.configurationsService.saveConfiguration(this.warehouse.hostName, this.userEmail, this.userName, this.configuration);
    }

    async refreshMapAndClearLocalStorage() {
        localStorage.clear();
        this.clearCacheCallback(true);
    }

    refreshMap(inicial: boolean = true) {
        if (inicial) {
            this.refreshMapModal.openModal();
            this.refreshMapSeconds = 30;
            interval(1000)
                .pipe(takeUntil(this.ngUnsubscribeInterval))
                .subscribe(() => {
                    this.refreshMapSeconds -= 1;
                    if (this.refreshMapSeconds <= 0) {
                        this.ngUnsubscribeInterval.next(true);
                        this.clearCacheCallback(true);
                    }
                });
        } else {
            this.ngUnsubscribeInterval.next(true);
        }
    }

    async clearCacheCallback(clearAndReload: boolean = false) {
        await this.idbService.clear(IDB_STORES.HTTP);
        if (clearAndReload) window.location.reload();
    }

    toggleFilters() {
        this.showFilters = !this.showFilters;
        this.stateService.setShowFilters(this.showFilters);
    }
}
