import { Component, OnInit, OnDestroy, AfterViewInit, inject, DestroyRef } from '@angular/core';
import { Location, DOCUMENT, NgIf, AsyncPipe, DatePipe } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AuthenticationService } from './core/shared/authentication/authentication.service';
import { ArchivesService } from './core/shared/archives/archives.service';
import { EquipmentsService } from './map/equipments/equipments.service';
import { StateService } from './core/shared/state/state.service';
import { LinesService } from './map/lines/shared/lines.service';
import { filter, catchError, take } from 'rxjs/operators';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AppLoadService } from './app-load.service';
import { ErrorService, TableComponentDirective, AysCommonsModule } from '@ays';
import { forkJoin, of } from 'rxjs';
import * as _ from 'lodash-es';
import { SettingsService } from './core/shared/settings/settings.service';
import { ConfigurationsService } from './core/shared/configurations/configurations.service';
import { ColorsService, ColorStructure } from './shared/services/colors.service';
import $ from 'jquery';
import { DownloadResourceIconPipe } from './shared/pipes/download-resource-icon.pipe';
import { ArchiveTypePipe } from './shared/pipes/archive-type.pipe';
import { DisplayFilterPipe } from './shared/pipes/display-filter.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NavCustomComponent } from './core/components/nav-custom/nav-custom.component';
import { LinesDataModalComponent } from './shared/components/lines-data-modal/lines-data-modal.component';
import { PreloaderComponent } from './core/components/preloader/preloader.component';
import { SvgPatternsComponent } from './shared/components/svg-patterns/svg-patterns.component';
import { SignalRServices, SignalRStatusClient } from './notifications/shared/clients/signalr-status.client';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SignalRStatusService } from './notifications/shared/handlers/signalr-status-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        AysCommonsModule,
        NgxDatatableModule,
        AsyncPipe,
        DatePipe,
        DisplayFilterPipe,
        ArchiveTypePipe,
        DownloadResourceIconPipe,
        NavCustomComponent,
        LinesDataModalComponent,
        RouterOutlet,
        PreloaderComponent,
        SvgPatternsComponent
    ],
})
export class AppComponent extends TableComponentDirective<any> implements OnInit, AfterViewInit, OnDestroy {
    pageNaked = false;
    auwaStart = false;
    isDisconnected = false;
    isReconnecting = false;
    currentUser: any;
    lines: any = [];
    equipments: any = [];
    testActivity = false;
    getReportList: () => void;
    private destroyRef = inject(DestroyRef);

    private readonly msalService = inject(MsalService);
    private readonly msalBroadcastService = inject(MsalBroadcastService);
    private readonly configurationsService = inject(ConfigurationsService);
    private readonly settingsService = inject(SettingsService);
    private readonly router = inject(Router);
    private readonly location = inject(Location);
    private readonly state = inject(StateService);
    private readonly appLoadService = inject(AppLoadService);
    private readonly authenticationService = inject(AuthenticationService);
    private readonly archivesService = inject(ArchivesService);
    private readonly linesService = inject(LinesService);
    private readonly equipmentsService = inject(EquipmentsService);
    private readonly errorService = inject(ErrorService);
    private readonly signalRClient = inject(SignalRStatusClient);
    private readonly signalRService = inject(SignalRStatusService);
    // private readonly pageLifeCycleService = inject(PageLifecycleService);
    private readonly colorsService = inject(ColorsService);
    private _document: Document | null = inject(DOCUMENT, { optional: true });

    constructor(
        //@Inject(DOCUMENT) private _document: Document,
    ) {
        super();
        if (this.router.url === '/unauthorized') {
            this.pageNaked = true;
        } else {
            this.appLoadService.getCurrentWarehouse.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
                const path = this.location.path();
                if (!path.startsWith(`/warehouse/${res.warehouse}`)) {
                    this.router.navigate([`warehouse/${res.warehouse}`]);
                }
            });
        }
        this.appLoadService.getCurrentConfiguration.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((res) => {
            if (res.darkMode) {
                this._document.documentElement.classList.add('dark');
            } else {
                this._document.documentElement.classList.remove('dark');
            }
        });

        this.currentUser = this.authenticationService.getUser();
    }

    ngAfterViewInit(): void {
        //https://github.com/vitejs/vite/discussions/9601
        //https://sergiocarracedo.es/2020/07/17/sharing-variables-between-scss-and-typescript/
        //https://developer.mozilla.org/en-US/docs/Web/API/Window/getComputedStyle
        const bodyRootStyles = window.getComputedStyle(document.body); //we can use an individual element
        const colorsToSet: ColorStructure[] = this.colorsService.getColors();
        colorsToSet.forEach((c) => {
            const prefix = '--';
            if (bodyRootStyles.getPropertyValue(prefix + c.styleId)) {
                this.colorsService.setColor(c.styleId, bodyRootStyles.getPropertyValue(prefix + c.styleId));
            }
        });
        this.getReportList = () => this.archivesService
            .getArchivesRemoteStorage()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((res) => {
                this.setDatatableItems(res);
            });

        const myOffcanvas = document.getElementById('offcanvasArchives');
        myOffcanvas.addEventListener('shown.bs.offcanvas', this.getReportList);
        // if (
        //     ['fernandoph@inditex.com', 'cesargs@ext.inditex.com', 'angelapen@ext.inditex.com', 'josecd@servicioexterno.stradivarius.es'].includes(
        //         this.currentUser.userName,
        //     )
        // ) {
        //     $('.navbar-brand')[0].innerHTML = `
        //     <span id="previousState" class="badge bg-secondary" style="font-size:10px;margin-left:5px;">Previous state</span>
        //     <span id="currentState" class="badge bg-success" style="font-size:10px">Current state</span>
        //     <span id="messageTime" class="badge bg-primary" style="font-size:10px;"></span>
        //     <span id="jsTime" class="badge bg-warning" style="font-size:10px;margin-right:5px;">${new Date().toLocaleTimeString()}</span>`;
        //     this.pageLifeCycleService.listenVisibilityChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ prevState, state }) => {
        //         try {
        //             $('#previousState')[0].innerHTML = `${prevState}`;
        //             $('#currentState')[0].innerHTML = `${state}`;
        //         } catch (error) { }
        //     });
        //     setInterval(() => {
        //         $('#jsTime')[0].innerHTML = `${new Date().toLocaleTimeString()}`;
        //     }, 60000);
        // }
    }

    ngOnInit(): Promise<void> {
        this.msalService.handleRedirectObservable().subscribe();

        this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => {
                this.checkUserAndRoles();
            });

        forkJoin({
            lines: this.linesService.getLinesNames().pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar los nombres de equipos');
                    return of(null);
                }),
            ),
            equipments: this.equipmentsService.getEquipmentNames().pipe(
                take(1),
                catchError((err) => {
                    console.log('Ha ocurrido un problema al recuperar los nombres de equipos');
                    return of(null);
                }),
            ),
        })
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(({ lines, equipments }) => {
                this.lines = lines;
                this.equipments = equipments.filter((eq) => eq !== null);
            });
        // const onReconnected = _.throttle(this.onReconnected.bind(this));
        this.signalRService.terminateWorker();
        const onDisconnected = _.throttle(this.onDisconnected.bind(this));
        const onReconnecting = _.throttle(this.onReconnecting.bind(this));
        const onLockFailed = _.throttle(this.onAcquireLockFailed.bind(this));
        this.signalRService.getOnReconnectSubject().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.onReconnected());
        return Promise.all([
            this.signalRClient.connectToHub(SignalRServices.ACTIVE_ALERT_COUNT, onDisconnected, onReconnecting, onLockFailed),
            this.signalRClient.connectToHub(SignalRServices.REPORT_STATUS),
            this.signalRClient.connectToHub(SignalRServices.SERVICE_STATUS),
            this.signalRClient.connectToHub(SignalRServices.WAREHOUSE_STATUS),
            this.signalRClient.connectToHub(SignalRServices.WAREHOUSE_VARIABLE_STATUS),
        ]).then(() => {
            console.log('All websockets connected');
            console.log('Localstorage signalRProtocol. Need to be 0/null for Binary messages or 1 for Json messages.');
        });
    }

    ngOnDestroy(): void {
        console.log('onDestroy');
        const myOffcanvas = document.getElementById('offcanvasArchives');
        myOffcanvas?.removeEventListener('shown.bs.offcanvas', this.getReportList);
        this.signalRClient.disconnectFromHub(SignalRServices.ACTIVE_ALERT_COUNT);
        this.signalRClient.disconnectFromHub(SignalRServices.REPORT_STATUS);
        this.signalRClient.disconnectFromHub(SignalRServices.SERVICE_STATUS);
        this.signalRClient.disconnectFromHub(SignalRServices.WAREHOUSE_STATUS);
        this.signalRClient.disconnectFromHub(SignalRServices.WAREHOUSE_VARIABLE_STATUS);
        this.signalRService.terminateWorker();
        // this.ngUnsubscribe.next(true);
        // this.ngUnsubscribe.complete();
    }

    checkUserAndRoles() {
        const userDetails = this.authenticationService.getUser();
        if (userDetails) {
            this.currentUser = this.authenticationService.getUser();
            this.authenticationService.setGroups().then(() => {
                this.authenticationService.getGroups.subscribe((groups) => {
                    this.appLoadService.rolesComplete = true;
                    if (!groups || groups.length === 0) {
                        this.router.navigate(['/403']);
                    } else {
                        this.settingsService.getBackendConfiguration().subscribe((backendConfiguration) => {
                            this.settingsService.authenticationSettings.groups = groups;
                            this.settingsService.globalSettings = backendConfiguration.version;
                            this.settingsService.applicationInsightsSettings = backendConfiguration.instrumentationKey;
                            this.appLoadService.warehouses = this.settingsService.filterWarehouses(backendConfiguration);
                            this.appLoadService.warehouses = this.appLoadService.castWarehouse(this.appLoadService.warehouses);
                            this.appLoadService.setWarehouse(this.appLoadService.warehouses);

                            this.appLoadService.getCurrentWarehouse.subscribe((res) => {
                                forkJoin([ //as fork, becouse we need both at same time
                                    this.configurationsService.readConfiguration(res.hostName, this.currentUser.userName),
                                    this.configurationsService.readConfiguration(res.hostName, res.warehouse)]
                                ).subscribe(([userConfiguration, whConfiguration]) => {
                                    if (userConfiguration) {
                                        const configuration = this.appLoadService.castConfiguration(userConfiguration);
                                        this.appLoadService.setCurrentConfiguration(configuration);
                                    }
                                    // read warehouse configuration
                                    if (whConfiguration) {
                                        const configuration = this.appLoadService.castGlobalConfiguration(whConfiguration);
                                        this.appLoadService.setGlobalConfiguration(configuration);
                                    }
                                    this.auwaStart = true;
                                    this.router.initialNavigation();
                                });
                            })


                        })
                    }
                })
            });

        } else {
            this.authenticationService.msalService.loginRedirect();
        }
    }

    onReconnected() {
        this.isDisconnected = false;
        this.isReconnecting = false;
    }

    onDisconnected() {
        this.isDisconnected = true;
        this.isReconnecting = true;
    }

    onReconnecting() {
        this.isReconnecting = true;
    }

    onAcquireLockFailed() {
        const errorInstance = {
            type: 'Warning',
            title: 'Web Lock acquisition failed',
            code: '-',
            msg: 'Your browser does not support Web Locks. This could cause that some notifications would be missed.',
        };
        this.errorService.add(errorInstance);
    }

    saveFullscreen(fullscreen) {
        this.state.setFullscreen(fullscreen);
    }
}
