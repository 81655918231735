import { Routes } from '@angular/router';
import { CanActivateRoute } from './core/shared/authentication/authentication.guard';
import { CustomsResolver } from '@customs/shared/customs.resolver';
import { FloorsResolver } from '@floors/shared/floors.resolver';
import { environment } from '../environments/environment.prod';
import { LinesResolver } from '@lines/shared/lines.resolver';
import { ZonesResolver } from '@zones/shared/zones.resolver';
import { AreasResolver } from '@areas/shared/areas.resolver';
import { HomeResolver } from '@home/shared/home.resolver';
import { CustomResolver } from '@custom/custom.resolver';

export const APPLICATION_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('./core/components/preloader/preloader.component').then(c => c.PreloaderComponent),
    },
    {
        path: 'landing',
        loadComponent: () => import('./landing/landing.component').then(c => c.LandingComponent),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id',
        canActivate: [CanActivateRoute],
        children: [
            {
                path: '',
                loadComponent: () => import('./map/home/home.component').then(c => c.HomeComponent),
                canActivate: [CanActivateRoute],
                runGuardsAndResolvers: 'always',
                resolve: { home: HomeResolver },
                children: [
                    {
                        path: 'lines',
                        loadComponent: () => import('./map/home/components/lines/home-lines.component').then(c => c.HomeLinesComponent),
                        canActivate: [CanActivateRoute],
                        title: 'Auwa: Home Lines',
                    },
                    {
                        path: 'customs',
                        loadComponent: () => import('./map/home/components/customs/home-customs.component').then(c => c.HomeCustomsComponent),
                        canActivate: [CanActivateRoute],
                        title: 'Auwa: Home Customs',
                    },
                ],
            },
            {
                path: 'customs/:customName',
                loadComponent: () => import('./map/customs/components/customs.component').then(c => c.CustomsComponent),
                canActivate: [CanActivateRoute],
                resolve: { customs: CustomsResolver },
                runGuardsAndResolvers: 'always',
                title: 'Auwa: Custom',
            },
            {
                path: 'floor/:floorId',
                children: [
                    {
                        path: '',
                        loadComponent: () => import('./map/floors/components/floors.component').then(c => c.FloorsComponent),
                        canActivate: [CanActivateRoute],
                        resolve: { floor: FloorsResolver },
                        runGuardsAndResolvers: 'always',
                        title: 'Auwa: Floor',
                    },
                    {
                        path: 'area/:areaId',
                        children: [
                            {
                                path: '',
                                loadComponent: () => import('./map/areas/components/areas.component').then(c => c.AreasComponent),
                                canActivate: [CanActivateRoute],
                                resolve: { area: AreasResolver },
                                runGuardsAndResolvers: 'always',
                                title: 'Auwa: Area',
                            },
                            {
                                path: 'zone/:zoneId',
                                children: [
                                    {
                                        path: '',
                                        loadComponent: () => import('./map/zones/components/zones.component').then(c => c.ZonesComponent),
                                        canActivate: [CanActivateRoute],
                                        resolve: { zone: ZonesResolver },
                                        runGuardsAndResolvers: 'always',
                                        title: 'Auwa: Zone',
                                    },
                                    {
                                        path: 'line/:lineId',
                                        children: [
                                            {
                                                path: '',
                                                loadComponent: () => import('./map/lines/components/lines.component').then(c => c.LinesComponent),
                                                canActivate: [CanActivateRoute],
                                                resolve: { line: LinesResolver },
                                                runGuardsAndResolvers: 'always',
                                                title: 'Auwa: Line',
                                            },
                                        ],
                                        //canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                                    },
                                ],
                            },
                        ],
                        //canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
                    },
                ],
                //canActivate: environment.mode === 'front' ? [] : [MsalGuard, CanActivateRoute],
            },
        ],
    },
    {
        path: 'warehouse/:id/dashboard',
        loadComponent: () => import('./custom/custom.component').then(c => c.CustomComponent),
        canActivate: [CanActivateRoute],
        resolve: { home: CustomResolver },
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'warehouse/:id/alerts',
        loadChildren: () => import('./alerts/shared/alerts.routes').then((m) => m.ALERT_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/warnings',
        loadChildren: () => import('./warnings/shared/warnings.routes').then((m) => m.WARNING_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/reports',
        loadChildren: () => import('./reports/shared/reports.routes').then((m) => m.REPORT_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/variables',
        loadChildren: () => import('./variables/shared/variables.routes').then((m) => m.VARIABLE_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/scanners',
        loadChildren: () => import('./scanners/shared/scanners.routes').then((m) => m.SCANNER_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/analytics',
        loadChildren: () => import('./powerbi/powerbi.routes').then((m) => m.POWERBI_ROUTES),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/settings',
        loadComponent: () => import('./shared/components/settings/settings.component').then(c => c.SettingsComponent),
        canActivate: [CanActivateRoute],
    },
    {
        path: 'warehouse/:id/userManual',
        loadComponent: () => import('./user-manual/user-manual.component').then(c => c.UserManualComponent),
        canActivate: [CanActivateRoute],
    },
    {
        path: '404',
        loadComponent: () => import('./core/components/not-found/not-found.component').then(c => c.NotFoundComponent)
    },
    {
        path: 'unauthorized',
        loadComponent: () => import('./core/components/unauthorized/unauthorized.component').then(c => c.UnauthorizedComponent)

    },
    { path: '**', redirectTo: '/404' },
];